import gql from 'graphql-tag';

const AUTO_SUGGEST_GOVERNMENT_ENTITIES = input => gql`
{
  topGoventities(_approxgoventityname: "${input}") {
    candidatesGoventity
    confidence
  }
}
`

export default AUTO_SUGGEST_GOVERNMENT_ENTITIES
