import gql from 'graphql-tag';

const AUTO_SUGGEST_LEGISLATOR_NAME = input => {
  const split = input.trim().split(", ");
  const lastName = split[0] || "";
  const firstName = split[1] || "";

  return gql`
  {
    topLegislator(inputWrongFirstName: "${firstName}", inputWrongLastName: "${lastName}") {
      candidateFirstName
      candidateLastName
      confidence
    }
  }
  `
}

export default AUTO_SUGGEST_LEGISLATOR_NAME
