export default function mapIssueCode(i) {
  const issueMap = new Map([
    ["ACC", "Accounting"],
    ["HOM", "Homeland Security"],
    ["ADV", "Advertising"],
    ["HOU", "Housing"],
    ["AER", "Aerospace"],
    ["IMM", "Immigration"],
    ["AGR", "Agriculture"],
    ["IND", "Indian/Native American Affairs"],
    ["ALC", "Alcohol & Drug Abuse"],
    ["INS", "Insurance"],
    ["ANI", "Animals"],
    ["LBR", "Labor Issues/Antitrust/Workplace"],
    ["APP", "Apparel/Clothing Industry/Textiles"],
    ["INT", "Intelligence and Surveillance"],
    ["ART", "Arts/Entertainment"],
    ["LAW", "Law Enforcement/Crime/Criminal Justice"],
    ["AUT", "Automotive Industry"],
    ["MAN", "Manufacturing"],
    ["AVI", "Aviation/Aircraft/Airlines"],
    ["MAR", "Marine/Maritime/Boating/Fisheries"],
    ["BAN", "Banking"],
    ["MED", "Medical/Disease Research/Clinical Labs"],
    ["BNK", "Bankruptcy"],
    ["MIA", "Media (Information/Publishing)"],
    ["BEV", "Beverage Industry"],
    ["MMM", "Medicare/Medicaid"],
    ["BUD", "Budget/Appropriations"],
    ["MON", "Minting/Money/Gold Standard"],
    ["CAW", "Clean Air & Water (Quality)"],
    ["NAT", "Natural Resources"],
    ["CDT", "Commodities (Big Ticket)"],
    ["PHA", "Pharmacy"],
    ["CHM", "Chemicals/Chemical Industry"],
    ["POS", "Postal"],
    ["CIV", "Civil Rights/Civil Liberties"],
    ["RRR", "Railroads"],
    ["COM", "Communications/Broadcasting/Radio/TV"],
    ["RES", "Real Estate/Land Use/Conservation"],
    ["CPI", "Computer Industry"],
    ["REL", "Religion"],
    ["CSP", "Consumer Issues/Safety/Protection"],
    ["RET", "Retirement"],
    ["CON", "Constitution"],
    ["ROD", "Roads/Highway"],
    ["CPT", "Copyright/Patent/Trademark"],
    ["SCI", "Science/Technology"],
    ["DEF", "Defense"],
    ["SMB", "Small Business"],
    ["DOC", "District of Columbia"],
    ["SPO", "Sports/Athletics"],
    ["DIS", "Disaster Planning/Emergencies"],
    ["TAR", "Miscellaneous Tariff Bills"],
    ["ECN", "Economics/Economic Development"],
    ["TAX", "Taxation/Internal Revenue Code"],
    ["EDU", "Education"],
    ["TEC", "Telecommunications"],
    ["ENG", "Energy/Nuclear"],
    ["TOB", "Tobacco"],
    ["ENV", "Environmental/Superfund"],
    ["TOR", "Torts"],
    ["FAM", "Family Issues/Abortion/Adoption"],
    ["TRD", "Trade (Domestic & Foreign)"],
    ["FIR", "Firearms/Guns/Ammunition"],
    ["TRA", "Transportation"],
    ["FIN", "Financial Institutions/Investments/Securities"],
    ["TOU", "Travel/Tourism"],
    ["FOO", "Food Industry (Safety, Labeling, etc.)"],
    ["TRU", "Trucking/Shipping"],
    ["FOR", "Foreign Relations"],
    ["URB", "Urban Development/Municipalities"],
    ["FUE", "Fuel/Gas/Oil"],
    ["UNM", "Unemployment"],
    ["GAM", "Gaming/Gambling/Casino"],
    ["UTI", "Utilities"],
    ["GOV", "Government Issues"],
    ["VET", "Veterans"],
    ["HCR", "Health Issues"],
    ["WAS", "Waste (hazardous/solid/interstate/nuclear)"],
    ["WEL", "Welfare"]
  ]);

  return issueMap.get(i);
}
