type IssueType = {
  in_house: number | null,
  issue_code: string | null,
  out_house: number | null,
  super_issue_code: number | null,
}

type ReportYearIssues = {
  issues: IssueType[],
  report_year: number,
}


type OutputDataType = {
  name: string,
  values: [number, number]
}[]


export default function processInOutBarChart(
  data: ReportYearIssues[],
  legendKeys: {
    color:string,
    show:boolean,
    text:string,
  }[],
) {
  const issueCodes:{[code: string]: [number, number]} = {};
  for (let i = 0; i < data.length; i++) { //iterate through years
    const reportYear = data[i]
    for (let j = 0; j < reportYear.issues.length; j++) { //iterate through issues
      const issue = reportYear.issues[j]
      const code = issue.issue_code;
      if(typeof code === "string" && code.length>0) { //if this code is valid
        const inhouse = issue.in_house || 0;
        const outhouse = issue.out_house || 0;

        //if we are seeing this code for the first time
        if (issueCodes[code] === undefined) {
          //initialize the array with in and out house values set to 0
          issueCodes[code] = [0,0];
        }

        const sums: [number, number] = issueCodes[code] //[out sourced value, in house value]
        if (sums){
          //then the value associated with the code is increased
          if (legendKeys?.[0]?.show){
            sums[0] += outhouse
          }
          if (legendKeys?.[1]?.show){
            sums[1] += inhouse
          }
        }
      }
    }
  }

  const processedData:OutputDataType = []
  for (const code in issueCodes){
    //the final data structure, where values is an array, with outsourced being indexed at values[0] and in-house being at values[1]
    processedData.push({name: code, values: issueCodes[code]})
  }

  //sorted data from highest to lowest value of combined bars
  processedData.sort((a, b) => b.values[0]+b.values[1] - a.values[0]-a.values[1])
  console.log(JSON.stringify(processedData))
  return processedData
}
