/**
 * [parseBillId tries parsing the provided billId]
 * @param  {Object}  billId        [bill id string]
 * @param  {Boolean} [strict=true] [if this is true, returns null if the id is invalid, else even if the id is invalid it will try to fill in some default values]
 * @return {Object | null}                [return null if invalid id and strict, else returns object with parsed bill id]
 */
const parseBillId = (billId, strict=true) => {
  //set some default values
  let congressSession = null;
  let billChamber = null;
  let billNumber = null;
  let billResolutionType = null;
  let valid = true;

  //try parsing the congress session
  const split = billId.split(/_(.+)/); //split the bill id only by the first encountered underscore (ex "112_H_J_456" becomes ["112", "H_J_456", ""])
  if( !isNaN( parseInt(split[0]) ) ) { //if the congress number is a valid integer
    congressSession = parseInt(split[0]);
  }
  else {
    valid = false; //invalid because the congress session isn't a number
  }

  if(split[1]) { //if there is a second section in the string
    //try parsing the chamber
    if(split[1].toLowerCase().indexOf("h") !== -1) { //there is an h character in the strong
      billChamber = "H";
    }
    else if(split[1].toLowerCase().indexOf("s") !== -1) { //there is an s character in the strong
      billChamber = "S";
    }
    else {
      valid = false; //invalid because the bill chamber isn't house or senate
    }

    //try parsing the resolution type
    if(split[1].toLowerCase().indexOf("j") !== -1) {
      billResolutionType = "J";
    }
    else if(split[1].toLowerCase().indexOf("c") !== -1) {
      billResolutionType = "C";
    }
    else if(split[1].toLowerCase().indexOf("r") !== -1) {
      billResolutionType = "R";
    }


    //try parsing the bill number
    const match = split[1].match(/\d+/); //try to get the bill number out of the second section
    if( match!==null && !isNaN( parseInt(match[0]) ) ) { //if there were numbers in the string
      billNumber = parseInt(match[0]);
    }
    else {
      valid = false; //invalid because the bill number doesn't exist or isnt a number
    }
  }
  else {
    valid = false; //invalid because the second section doesn't exist
  }

  if(strict && valid!==true) { //if strict and the bill id is invalid
    return null; //the bill id format is invalid
  }
  return { //else return what we have
    congressSession,
    billChamber,
    billNumber,
    billResolutionType
  }
}

export default parseBillId
