import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import WordCloudOld from 'react-d3-cloud';
import WordCloud from 'word-cloud-d3-react'
import "./wordCloudContainer.scss";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import * as d3 from 'd3'

import Slider from 'components/slider/Slider'

export default class WordCloudContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sliderNumber: 20,
      gramIndex: 2, //corresponds to the index of gram to show (gramIndex + 1 = gram show, ex gramIndex===2, gram show is 3)
    };
  }

  setGramIndex(gramIndex) {
    if(gramIndex>=0 && gramIndex<this.props.wordCloudData.length) {
      this.setState({gramIndex: gramIndex});
    }
  }

  getWordCloud = () => {
    if(this.props.wordCloudData[this.state.gramIndex]) { //if we have data for this gram
      const data = this.props.wordCloudData[this.state.gramIndex].slice(0,this.state.sliderNumber) //slice off some data points depending on the slider
      return (
        <div>
          <WordCloud
            data={data}
            height={this.props.height}
            colorFunction={d3.scaleOrdinal().domain(data.map(d => d.key)).range(["#EC7063", "#F39C12", "#F1C40F", "#2ECC71", "#3498DB", "#AF7AC5", "#7D3C98"])}
          />

          {/* <div style={{border:"1px solid black"}}>
            <WordCloudOld
              data={this.props.wordCloudData[this.state.gramIndex].slice(0,this.state.sliderNumber).map(d => ({
                ...d,
                text: d.key,
              }))}
              fontSizeMapper={d => d.value}
              height={this.props.height}
              width={this.props.width}
              font="Impact"
            />
          </div> */}
        </div>
      )
    }
  }


  render() {
    if(this.props.wordCloudData.length > 0) {
      let gramOptions = [];
      for(let i=0; i<this.props.wordCloudData.length; ++i) {
        gramOptions.push(i);
      }

      const sliderMax = 10*Math.ceil(Math.max(...this.props.wordCloudData.map(d => d.length))/10); //the maximum number of text for all the grams, rounded to the next highest 10

      return (
        <React.Fragment>
          <Row>
            <Col sm={12} md={9}>
              <div><strong># Words to show: {this.state.sliderNumber}</strong></div>

              <Slider
                step={10}
                min={10}
                max={sliderMax}
                value={this.state.sliderNumber}
                onChange={e => this.setState({sliderNumber: e.target.value})}
              />
            </Col>


            <Col sm={12} md={3}>
              <div style={{position: "relative"}}>
                <Dropdown className="wordCloudDropdown">
                  <Dropdown.Toggle variant="secondary">
                    Gram: {this.state.gramIndex + 1}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {gramOptions.map((option, i) =>
                      <Dropdown.Item key={option} onClick={() => this.setGramIndex(option)}>{option + 1}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>

          {this.getWordCloud()}
        </React.Fragment>
      );
    }

    else if(this.props.requestStatus === "loading") {
      return "Loading...";
    }
    else if(this.props.requestStatus === "error") {
      return "There was an error getting the data. Please try again later.";
    }
    else if(this.props.requestStatus === "done") {
      return "There is no data from the query for this visualization";
    }
    else if(this.props.requestStatus === "") {
      return "Please use the form to get data for this visualization";
    }

    return "";
  }
}

WordCloudContainer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  wordCloudData: PropTypes.array.isRequired,
  requestStatus: PropTypes.string.isRequired,
}
