import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { navigate } from "gatsby"

import "./form.scss";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTimes, faSearch, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

import AUTO_SUGGEST_SIG_NAME from "utils/graphql/autoSuggest/sigName/sigName";
import AUTO_SUGGEST_SIG_NAICS from "utils/graphql/autoSuggest/sigNaics/sigNaics";
import AUTO_SUGGEST_SIG_SIC from "utils/graphql/autoSuggest/sigSic/sigSic";
import AUTO_SUGGEST_SIG_TICKER from "utils/graphql/autoSuggest/sigTicker/sigTicker";
import AUTO_SUGGEST_SIG_GVKEY from "utils/graphql/autoSuggest/sigGvkey/sigGvkey";
import AUTO_SUGGEST_LEGISLATOR_NAME from "utils/graphql/autoSuggest/legislatorName/legislatorName";
import AUTO_SUGGEST_ISSUES from "utils/graphql/autoSuggest/issues/issues";
import AUTO_SUGGEST_YEAR from "utils/graphql/autoSuggest/year/year";
import { AUTO_SUGGEST_BILL_ID, AUTO_SUGGEST_BILL_ID_VARIABLES, AUTO_SUGGEST_BILL_TITLE } from "utils/graphql/autoSuggest/bill/bill";
import AUTO_SUGGEST_GOVERNMENT_ENTITIES from "utils/graphql/autoSuggest/governmentEntities/governmentEntities";
import AUTO_SUGGEST_REGISTRANT from "utils/graphql/autoSuggest/registrant/registrant";

import isBrowser from "utils/isBrowser"
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";

import AutoSuggestion from "components/autoSuggestion/AutoSuggestion.js";

class FormComponent extends Component {
  constructor(props) {
    super(props)

    //the four functions you need to define are (in order of when they would be called in the auto suggestion workflow)
    //getQuery: given the input, return the graphql function
    //processResponse: given the response, return the array of suggestion objects
    //getSuggestionString: given a suggestion object, return the string to be displayed
    //clickSuggestion: when the user clicks on the suggestion, how should the state change


    this.TYPES = [
      {
        name: "Name",
        placeholder: "e.g. Apple",
        getQuery: AUTO_SUGGEST_SIG_NAME,
        processResponse: response => {
          if (!response.data || !response.data.topClientsByFuzzyName) { throw Error("Response error"); }
          return response.data.topClientsByFuzzyName; //returns the array of top clients/companies
        },
        getSuggestionString: suggestion => suggestion.candidatesClient.clientName, //given a top client/company object, returns the client/company name
        clickSuggestion: (self, suggestion) => {
          //the following two lines follow the behavior seen on https://beta3.lobbyview.org/query#!/
          this.setState({group: suggestion.candidatesClient.clientName}); //set the name of this company to this component's state (and also filling in the input)
          self.clearSuggestions(); //clear the suggestions
        },
      },
      {
        minInputLength: 1,
        name: "NAICS",
        placeholder: "e.g. 1234",
        getQuery: AUTO_SUGGEST_SIG_NAICS,
        processResponse: response => {
          if (!response.data || (!response.data.topNaics)) { throw Error("Response error"); }
          return response.data.topNaics;
        },
        getSuggestionString: suggestion => suggestion.naics.naics+" "+suggestion.naics.naicsTitle,
        clickSuggestion: (self, suggestion) => {
          this.setState({group: suggestion.naics.naics+" "+suggestion.naics.naicsTitle});
          self.clearSuggestions(); //clear the suggestions
        },
      },
      {
        minInputLength: 1,
        name: "SIC",
        placeholder: "e.g. 541034",
        getQuery: AUTO_SUGGEST_SIG_SIC,
        processResponse: response => {
          if (!response.data || !response.data.topSic4) { throw Error("Response error"); }
          return response.data.topSic4;
        },
        getSuggestionString: suggestion => suggestion.candidateSic4Code + " " + suggestion.sic4Description,
        clickSuggestion: (self, suggestion) => {
          this.setState({group: suggestion.candidateSic4Code + " " + suggestion.sic4Description});
          self.clearSuggestions(); //clear the suggestions
        },
      },
      {
        name: "Ticker",
        placeholder: "e.g. AAPL",
        getQuery: AUTO_SUGGEST_SIG_TICKER,
        processResponse: response => {
          if (!response.data || !response.data.topTicsCodes) { throw Error("Response error"); }
          return response.data.topTicsCodes;
        },
        getSuggestionString: suggestion => suggestion.candidateTicCode,
        clickSuggestion: (self, suggestion) => {
          this.setState({group: suggestion.candidateTicCode});
          self.clearSuggestions(); //clear the suggestions
        },
      },
      {
        name: "GVKey",
        placeholder: "e.g. 00169",
        getQuery: AUTO_SUGGEST_SIG_GVKEY,
        processResponse: response => {
          if (!response.data || !response.data.topGvkeys) { throw Error("Response error: cant get gvkey autocomplete"); }
          return response.data.topGvkeys;
        },
        getSuggestionString: suggestion => suggestion.candidateGvkeyCode + " " + suggestion.companyCommonName,
        clickSuggestion: (self, suggestion) => {
          this.setState({group: suggestion.candidateGvkeyCode});
          self.clearSuggestions(); //clear the suggestions
        },
      },
    ];


    this.LEGISLATOR_NAME = {
      getQuery: AUTO_SUGGEST_LEGISLATOR_NAME,
      processResponse: response => {
        if (!response.data || !response.data.topLegislator) { throw Error("Response error"); }
        return response.data.topLegislator;
      },
      getSuggestionString: suggestion => { return suggestion.candidateLastName + ", " + suggestion.candidateFirstName },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          legislatorName: suggestion.candidateLastName + ", " + suggestion.candidateFirstName,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }


    this.ISSUES = {
      getQuery: AUTO_SUGGEST_ISSUES,
      processResponse: response => {
        if (!response.data || !response.data.topIssueCode) { throw Error("Response error"); }
        return response.data.topIssueCode;
      },
      getSuggestionString: suggestion => { return suggestion.candidateIssueCode.issueCodeDescription; },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          issueCode: suggestion.candidateIssueCode.issueCode,
          issueCodeDescription: suggestion.candidateIssueCode.issueCodeDescription,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.YEAR = {
      getQuery: AUTO_SUGGEST_YEAR,
      processResponse: response => {
        if (!response.data || !response.data.topLobbyingYear) { throw Error("Response error"); }
        return response.data.topLobbyingYear;
      },
      getSuggestionString: suggestion => { return suggestion.lobbyingYear.toString(); },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          year: suggestion.lobbyingYear,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.BILL_ID = {
      getQuery: AUTO_SUGGEST_BILL_ID,
      getVariables: AUTO_SUGGEST_BILL_ID_VARIABLES,
      processResponse: response => {
        console.log(response)
        if (!response.data || !response.data.topBills) { throw Error("Response error"); }
        return response.data.topBills;
      },
      getSuggestionString: suggestion => {
        const billResolutionType = suggestion.candidateBillId.billResolutionType || "";
        suggestion.suggestionString = (suggestion.candidateCongressSession+"_"+suggestion.candidateBillId.billChamber+billResolutionType+suggestion.candidateBillId.billNumber).toUpperCase()
        return suggestion.suggestionString;
      },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          billId: suggestion.suggestionString
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.BILL_TITLE = {
      getQuery: AUTO_SUGGEST_BILL_TITLE,
      processResponse: response => {
        if (!response.data || !response.data.bills) { throw Error("Response error"); }
        return response.data.bills;
      },
      getSuggestionString: suggestion => {
        const officialBills = suggestion.billTitlesByCongressNumberAndBillId.filter(s => s.billTitleType === "OFFICIAL")
        return officialBills[0].billTitle;
      },
      clickSuggestion: (self, suggestion) => {
        const officialBills = suggestion.billTitlesByCongressNumberAndBillId.filter(s => s.billTitleType === "OFFICIAL")
        const billResolutionType = suggestion.billId.billResolutionType || "";

        this.setState({
          billTitle: officialBills[0].billTitle,
          billId: (suggestion.congressNumber+"_"+suggestion.billId.billChamber+billResolutionType+suggestion.billId.billNumber).toUpperCase(),
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.GOVERNMENT_ENTITIES = {
      getQuery: AUTO_SUGGEST_GOVERNMENT_ENTITIES,
      processResponse: response => {
        if (!response.data || !response.data.topGoventities) { throw Error("Response error"); }
        return response.data.topGoventities;
      },
      getSuggestionString: suggestion => suggestion.candidatesGoventity,
      clickSuggestion: (self, suggestion) => {
        this.setState({
          governmentEntitiesInput: "", //clear input
          governmentEntities: this.state.governmentEntities.concat(suggestion.candidatesGoventity) //add this entity to the array
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.REGISTRANT = {
      getQuery: AUTO_SUGGEST_REGISTRANT,
      processResponse: response => {
        if (!response.data || !response.data.topRegistrants) { throw Error("Response error"); }
        return response.data.topRegistrants;
      },
      getSuggestionString: suggestion => suggestion.candidatesRegistrant.registrantName,
      clickSuggestion: (self, suggestion) => {
        this.setState({
          registrant: suggestion.candidatesRegistrant.registrantName,
          registrantUuid: suggestion.candidatesRegistrant.registrantUuid,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }






    const query = parseUrlQuery(isBrowser ? window.location.search.substring(1) : ""); //get the query in the url if any

    //set the initial type index and group depending on the query
    let initialSigTypeIndex = 0;
    let initialGroupValue = "";
    for(let sigTypeIndex=0; sigTypeIndex<this.TYPES.length; ++sigTypeIndex) { //see if any sig fields are present in the query
      const value = query[ "sig"+this.TYPES[sigTypeIndex].name ];
      if(value !== undefined) { //if a sig field is found
        //use the first sig field found to initialize the state
        initialSigTypeIndex = sigTypeIndex;
        initialGroupValue = value;
        break;
      }
    }

    this.state = {
      sigTypeIndex: initialSigTypeIndex,
      group: initialGroupValue,
      legislatorName: query.legislatorName || "",
      keywords: query.keywords || "",
      issueCode: query.issueCode || "", //this is actual value we need when we search for reports
      issueCodeDescription: query.issueCodeDescription || "", //this is the value we use to fill in the input
      year: !isNaN(parseInt(query.year)) ? parseInt(query.year) : "",
      billId: query.billId || "",
      billTitle: query.billTitle || "",
      governmentEntitiesInput: "", //this is the value we use to search for auto suggestions
      governmentEntities: (query.governmentEntities && query.governmentEntities.split(",")) || [], //these are the values we actually use in the query
      registrant: query.registrant || "", //this is the value we use to search for auto suggestions
      registrantUuid: query.registrantUuid || "", //this is the value we actually use to get the reports
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  clearForm = () => {
    navigate(window.location.pathname); //clear url

    //clear state
    this.setState({
      group: "",
      legislatorName: "",
      keywords: "",
      issueCode: "",
      issueCodeDescription: "",
      year: "",
      billId: "",
      billTitle: "",
      governmentEntitiesInput: "",
      governmentEntities: [],
      registrant: "",
      registrantUuid: "",
    });

    this.props.getReportsData({});
  }


  changeSigDropdown = newSigTypeIndex => {
    const query = parseUrlQuery(window.location.search.substring(1));

    this.setState({
      sigTypeIndex: newSigTypeIndex, //change which sig field type we are using
      group: query[ "sig"+this.TYPES[newSigTypeIndex].name ] || "" //try to autofill based on the query, else empty string
    })
  }


  handleSubmit(event) {
    this.props.showViz();
    event.preventDefault();
    event.target.classList.add("was-validated");

    if(event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
    }
    else {
      //add valid values to the query
      let query = {};
      if(this.state.group.trim().length>0) {
        query[ "sig"+this.TYPES[this.state.sigTypeIndex].name ] = this.state.group.trim(); //assign this value to the selected SIG type
      }
      if(this.state.legislatorName.trim().length>0) { query.legislatorName = this.state.legislatorName.trim(); }
      if(this.state.keywords.trim().length>0) { query.keywords = this.state.keywords.trim(); }
      if(this.state.issueCode.trim().length>0 && this.state.issueCodeDescription.trim().length>0) { query.issueCode = this.state.issueCode.trim(); }
      if(this.state.issueCodeDescription.trim().length>0) { query.issueCodeDescription = this.state.issueCodeDescription.trim(); }
      if(!isNaN(parseInt(this.state.year))) { query.year = this.state.year; } //if the year is a valid integer, add it to the query
      if(this.state.billId.trim().length>0) { query.billId = this.state.billId.trim(); }
      if(this.state.billTitle.trim().length>0) { query.billTitle = this.state.billTitle.trim(); } //this is included in the query but not actually used to get reports
      if(this.state.governmentEntities.length>0) { query.governmentEntities = this.state.governmentEntities.join(","); } //convert the array into a comma separated string
      if(this.state.registrant.trim().length>0) { query.registrant = this.state.registrant.trim(); } //this is included in the query but not actually used to get reports
      if(this.state.registrant.trim().length>0 && this.state.registrantUuid.trim().length>0) { query.registrantUuid = this.state.registrantUuid.trim(); } //registrantUuid should only be included if the registant is

      this.props.getReportsData(query);
    }
  }

  getKeywordsTooltip() {
    const renderTooltip = (
      <Tooltip>
        <b>Options:</b>
        <br/>
        <b>AND</b> combines searches, ex. <i>trade law AND shipping</i>
        <br/>
        <b>OR</b> either-or searches ex. <i>trade law OR shipping</i>
        <br/>
        <b>DASHES</b> exclude searches, ex. <i>trade law AND -shipping</i>
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <FontAwesomeIcon icon={faInfoCircle}/>
      </OverlayTrigger>
    )
  }


  render() {
    const sig = this.TYPES[this.state.sigTypeIndex];

    return (
      <React.Fragment>
        <div id="queryForm">
        <Form.Label onClick={this.props.toggleForm}>
          <FontAwesomeIcon id="toggle-filters" icon={faChevronCircleLeft} onClick={this.onClick}/>
          Hide Filters
        </Form.Label>
        <div className="line"></div>
        <Form.Label>Special Interest Group</Form.Label>
        <Form.Group style={{display: "flex"}}>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              {sig.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.TYPES.map((type,i) => <Dropdown.Item key={i} onClick={e => this.changeSigDropdown(i)}>{type.name}</Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>

          <AutoSuggestion
            minInputLength={sig.minInputLength || 3}
            maxSuggestions={5}
            placeholder={sig.placeholder}
            clickSuggestion={sig.clickSuggestion}
            processResponse={sig.processResponse}
            getSuggestionString={sig.getSuggestionString}
            getQuery={sig.getQuery}

            parentInput={this.state.group}
            parentChangeInput={input => this.setState({group: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Politician (Last Name, First Name)</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder={"e.g. McCain, John"}
            clickSuggestion={this.LEGISLATOR_NAME.clickSuggestion}
            processResponse={this.LEGISLATOR_NAME.processResponse}
            getSuggestionString={this.LEGISLATOR_NAME.getSuggestionString}
            getQuery={this.LEGISLATOR_NAME.getQuery}

            parentInput={this.state.legislatorName}
            parentChangeInput={input => this.setState({legislatorName: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Report Text Keyword(s) {this.getKeywordsTooltip()}</Form.Label>
          <Form.Control required type="text" placeholder="e.g. International Trade" value={this.state.keywords} onChange={e => this.setState({keywords: e.target.value})}/>
        </Form.Group>

        <Form.Group>
          <Form.Label>Report Issues</Form.Label>

          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder={"e.g. Taxation"}
            clickSuggestion={this.ISSUES.clickSuggestion}
            processResponse={this.ISSUES.processResponse}
            getSuggestionString={this.ISSUES.getSuggestionString}
            getQuery={this.ISSUES.getQuery}

            parentInput={this.state.issueCodeDescription}
            parentChangeInput={input => this.setState({issueCodeDescription: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Year</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder={"e.g. 2014"}
            clickSuggestion={this.YEAR.clickSuggestion}
            processResponse={this.YEAR.processResponse}
            getSuggestionString={this.YEAR.getSuggestionString}
            getQuery={this.YEAR.getQuery}

            type="number"
            parentInput={this.state.year}
            parentChangeInput={input => this.setState({year: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Bill Id (Congress #_TypeBill#)</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder="e.g. 111_HR3590"
            clickSuggestion={this.BILL_ID.clickSuggestion}
            processResponse={this.BILL_ID.processResponse}
            getSuggestionString={this.BILL_ID.getSuggestionString}
            getQuery={this.BILL_ID.getQuery}
            getVariables={this.BILL_ID.getVariables}

            type="text"
            parentInput={this.state.billId}
            parentChangeInput={input => this.setState({billId: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Bill Title</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder="e.g. Patient Protection and Affordable Care Act"
            clickSuggestion={this.BILL_TITLE.clickSuggestion}
            processResponse={this.BILL_TITLE.processResponse}
            getSuggestionString={this.BILL_TITLE.getSuggestionString}
            getQuery={this.BILL_TITLE.getQuery}

            type="text"
            parentInput={this.state.billTitle}
            parentChangeInput={input => this.setState({billTitle: input})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Government Entities</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder="e.g. DOD"
            clickSuggestion={this.GOVERNMENT_ENTITIES.clickSuggestion}
            processResponse={this.GOVERNMENT_ENTITIES.processResponse}
            getSuggestionString={this.GOVERNMENT_ENTITIES.getSuggestionString}
            getQuery={this.GOVERNMENT_ENTITIES.getQuery}

            type="text"
            parentInput={this.state.governmentEntitiesInput}
            parentChangeInput={input => this.setState({governmentEntitiesInput: input})}
          />
          {this.state.governmentEntities.map((entity,i) =>
            <div key={i}><FontAwesomeIcon icon={faTimes} onClick={e => {
              let governmentEntitiesCopy = this.state.governmentEntities.slice(0); //make a copy of the array
              governmentEntitiesCopy.splice(i, 1); //remove this entity being deleted
              this.setState({governmentEntities: governmentEntitiesCopy}); //set the state
            }} style={{cursor:"pointer"}}/> {entity}</div>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Registrant</Form.Label>
          <AutoSuggestion
            minInputLength={1}
            maxSuggestions={5}
            placeholder="e.g. Google"
            clickSuggestion={this.REGISTRANT.clickSuggestion}
            processResponse={this.REGISTRANT.processResponse}
            getSuggestionString={this.REGISTRANT.getSuggestionString}
            getQuery={this.REGISTRANT.getQuery}

            type="text"
            parentInput={this.state.registrant}
            parentChangeInput={input => this.setState({registrant: input})}
          />
        </Form.Group>

        <div>
          <Button onClick={this.handleSubmit} style={{float:"right"}}>Search <FontAwesomeIcon icon={faSearch}/></Button>

          <Button variant="secondary" onClick={this.clearForm}><FontAwesomeIcon icon={faTimes}/> Clear</Button>
        </div>
        </div>
      </React.Fragment>
    )
  }
}


FormComponent.propTypes = {
  getReportsData: PropTypes.func.isRequired, //function from Query parent to run when the form is submitted
  showViz: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
};

export default FormComponent
