import React, { Component } from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import mapIssueCode from 'utils/mapIssueCode/mapIssueCode';
import formatMoney from 'utils/formatMoney/formatMoney';
import formatLegendIssue from 'utils/formatLegendIssue/formatLegendIssue';

import Axes from './Axes'
import ResponsiveWrapper from './ResponsiveWrapper';
import Legend from './Legend';
import './BarChart.scss';

class BarChart extends Component {
  static propTypes = {
    legendKeys: PropTypes.array.isRequired,
    onClickLegendItem: PropTypes.func.isRequired,
    onClickBarItem: PropTypes.func.isRequired,
    padding: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    rotateLabels: PropTypes.bool.isRequired,
    margin: PropTypes.object.isRequired,
    customLegendElement: PropTypes.element
  }

  xScale = scaleBand();
  yScale = scaleLinear();

  getBarsForOneIssue = (issue, xScale, yScale, legendKeys) => {
    let formatBars = (this.props.formatBars ? this.props.formatBars : (x) => "$" + formatMoney(x));
    const mapLegendValue = this.props.mapLegendValue ? this.props.mapLegendValue : (x) => mapIssueCode(x.name); //How abbreviated bar names are displayed

    let bars = [];
    let sum = 0;
    for(let i=0; i<issue.values.length; ++i) {
      const x = xScale(issue.name) - xScale.bandwidth()/2;
      const y = yScale(issue.values[i]+sum);
      const width = xScale.bandwidth();
      const height = yScale(0)-yScale(issue.values[i]);
      bars.push((
        <g key={i} onClick={e => this.props.onClickBarItem(legendKeys[i].color)}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              <div>
              {mapLegendValue(issue)}<br/>
              {formatLegendIssue(legendKeys[i].text,legendKeys[i].color)}: {formatBars(issue.values[i])}
              </div>
            </Tooltip>
          }>
          <rect fill={legendKeys[i].color} x={x} y={y} height={height} width={width}></rect>
        </OverlayTrigger>
        </g>
      ))
      sum += issue.values[i]
      //stacked bars with tooltips are being created here
    }
    return bars;
  }


  render() {
    const margins = this.props.margin;
    const padding = this.props.padding || {xAxis: 20, yAxis: 20};

    const axesProps = {
      legend: {
          xAxis: this.props.xAxisLabel || "",
          yAxis: this.props.yAxisLabel || "",
      },
      ticksCount: 6,
      tickFormat: this.props.tickFormat
    }; //axes labels are defaulted to show nothing is no props are passed down

    const svgDimensions = {
        width: this.props.width,
        height: this.props.height,
    };

    const legendKeys = this.props.legendKeys;

    const { legend, ticksCount, tickFormat } = axesProps;

    const mapLegendValue = this.props.mapLegendValue ? this.props.mapLegendValue : (x) => mapIssueCode(x.name); //How abbreviated bar names are displayed

    let maxValue = 0;
    for (let i=0; i < this.props.data.length; i++){
      let total = 0
      for (let j=0; j< this.props.data[i].values.length; j++){
        total = total + this.props.data[i].values[j]
      }
      if (total > maxValue) {
        maxValue = total //calculating the max value of the bars on the viz to set the y-axis maximum
      }
    }

    const tickFormatWrapper = {
      xAxis(index) {
          if (tickFormat && typeof tickFormat.xAxis === 'function') {
              return tickFormat.xAxis();
          }
          return ;
      },
      yAxis: tickFormat && (tickFormat.yAxis ? tickFormat.yAxis : undefined),
    };

    const xScale = this.xScale
      .padding(0.5)
      .domain(this.props.data.map(d => d.name)) //renders the scale on the x-axis
      .range([margins.left, this.props.width - margins.right])

    const yScale = this.yScale
      .domain([0, maxValue*1.10]) //renders the scale of the y-axis
      .range([this.props.height - margins.bottom, margins.top])

    return (
      <div className="barchart">
      <div className="legend">{this.props.legendKeys ? (
        <Legend
          onClickLegendItem={this.props.onClickLegendItem}
          customLegendElement={this.props.customLegendElement}
          legendKeys={legendKeys}
          left={margins.left}
          showLegend = {this.props.showLegend}
        />
      ) : null}
      </div>
      <svg
        width={this.props.width}
        height={this.props.height}
        style={{
            overflow: "visible"
        }}
      >
      <Axes
        scales={{ xScale, yScale }}
        padding={padding}
        margins={margins}
        ticksCount={ticksCount}
        tickFormat={tickFormatWrapper}
        svgDimensions={svgDimensions}
        legend={legend}
        rotateLabels={this.props.rotateLabels}
      />
        {this.props.data.map((issue, issueIndex) =>
          <g key={issueIndex}>
            <g style = {{
                transform: `translate(${xScale(issue.name)}px, ${this.props.height-30}px)`
            }}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <div>
                    {mapLegendValue(issue)}<br/>
                    </div>
                  </Tooltip>
                }>
                  <text style = {this.props.rotateLabels ? {transform: `rotate(40deg) translate(-5px, 0)`, "textAnchor": "start", "whiteSpace": "noWrap"} : {}}>
                    {issue.name}
                  </text>
              </OverlayTrigger>
            </g> {/*labels for x-axis generated here*/}
            {this.getBarsForOneIssue(issue,xScale, yScale, legendKeys)} {/*call for bar creation function*/}
          </g>
        )}
      </svg>
      </div>
    )
  }
}

export default ResponsiveWrapper(BarChart)
