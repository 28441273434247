import gql from 'graphql-tag';

import getFirstWord from "utils/getFirstWord/getFirstWord";
import isMeaningfulString from "utils/isMeaningfulString/isMeaningfulString";
import mergeObjects from "utils/mergeObjects/mergeObjects";
import parseBillId from "utils/parseBillId/parseBillId";

//***IMPORTANT***: because the keywords need to be validated on the server, this function is asynchronous. You must use the async await syntax when using this function: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function
export default async function getReportsFilter(query, client) {
  //graphql query filtering based on this tutorial: https://www.howtographql.com/react-apollo/7-filtering-searching-the-list-of-links/
  let filter = {};
  if(isMeaningfulString(query.sigName)) {
    filter = mergeObjects(
      filter,
      {
        client: {
          clientName: {
            equalTo: query.sigName
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.sigNAICS)) {
    filter = mergeObjects(
      filter,
      {
        client: {
          or: [
            {clientsIndustryCode: { primaryNaics: { startsWithInsensitive: getFirstWord(query.sigNAICS) } } }
          ]
        }
      }
    )
  }
  if(isMeaningfulString(query.sigSIC)) {
    filter = mergeObjects(
      filter,
      {
        client: {
          or: [
            { clientsIndustryCode: { primarySic4: { startsWithInsensitive: getFirstWord(query.sigSIC) } } }
          ]
        }
      }
    )
  }
  if(isMeaningfulString(query.sigTicker)) {
    filter = mergeObjects(
      filter,
      {
        client: {
          clientsGvkeyExists: true,
          clientsGvkey: {
            compustatCompanyByGvkey: {
              compustatCompaniesNorthAmericaByGvkey: {
                tic: { likeInsensitive: "%"+query.sigTicker+"%" }
              }
            }
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.sigGVKey)) {
    filter = mergeObjects(
      filter,
      {
        client: {
          clientsGvkeyExists: true,
          clientsGvkey: {
            gvkey: {
              likeInsensitive: "%"+query.sigGVKey+"%"
            }
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.legislatorName)) {
    const split = query.legislatorName.trim().split(", ");
    const lastName = split[0] || "";
    const firstName = split[1] || "";

    filter = mergeObjects(
      filter,
      {
        issues: {
          some: {
            issueBillsByReportUuidAndIssueOrdi: {
              every: {
                billByCongressNumberAndBillId: {
                  legislator: {
                    legislatorLastName: { likeInsensitive: "%"+lastName.trim()+"%" },
                    legislatorFirstName: { likeInsensitive: "%"+firstName.trim()+"%" }
                  },
                  legislatorExists: true
                }
              }
            },
            issueBillsByReportUuidAndIssueOrdiExist: true
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.keywords)) {
    //to search for reports by keywords we need to
    //1) check whether the input has a valid websearch format
    //2) search for the report ids based on the results of step 1
    //3) include the report ids in the filter

    //1) check whether the input has a valid websearch format
    const isValidResults = await client.query({
      query: gql`
      {
        validatewebsearchinput(inSearchQuery: "${query.keywords}") {
          errorMessage
          isValid
        }
      }
      `,
    });


    //2) search for the report ids based on the results of step 1
    let reportUuids; //used to hold the report uuids returned
    if(isValidResults.data.validatewebsearchinput.isValid === true) { //if the string has a valid web search format
      const results = await client.query({ //search for the report ids using the web search format
        query: gql`
        {
          textsearchissueWebsearch(inText: "${query.keywords}") {
            reportsUuids
            issueOrdis
            scores
          }
        }
        `,
      });

      //if the data format is wrong
      if(!results.data || !results.data.textsearchissueWebsearch || !results.data.textsearchissueWebsearch.reportsUuids) {
        console.log("There was an error with the keywords results format", results);
      }
      else {
        reportUuids = results.data.textsearchissueWebsearch.reportsUuids; //set the report ids
      }

    }
    else { //else the keyword input does not have a valid websearch format
      const results = await client.query({ //search for the keyword directly
        query: gql`
        {
          textsearchissueTfidf(inText: "${query.keywords}") {
            reportsUuids
            issueOrdis
            scores
          }
        }
        `,
      });

      //if the data format is wrong
      if(!results.data || !results.data.textsearchissueTfidf || !results.data.textsearchissueTfidf.reportsUuids) {
        console.log("There was an error with the keywords results format", results);
      }
      else {
        reportUuids = results.data.textsearchissueTfidf.reportsUuids; //set the report ids
      }
    }


    //3) include the report ids in the filter
    filter = mergeObjects(
      filter,
      {
        reportUuid: {
          in: reportUuids.slice(0, 26000) //cap the number of report uuids included otherwise the request is too big
        }
      }
    )
  }
  if( !isNaN( parseInt(query.year) ) ) { //if the year is a valid integer, add it to the filter
    filter = mergeObjects(
      filter,
      {
        reportYear: {
          equalTo: parseInt(query.year)
        }
      }
    )
  }
  if( isMeaningfulString(query.issueCode) && isMeaningfulString(query.issueCodeDescription) ) {
    filter = mergeObjects(
      filter,
      {
        issuesExist: true,
        issues: {
          some: {
            issueCode: {
              equalTo: query.issueCode
            }
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.billId)) {
    const parsedBillId = parseBillId(query.billId); //get the parsed request, strict
    const billResolutionType = typeof parsedBillId.billResolutionType==="string" ? { equalTo: parsedBillId.billResolutionType } : { isNull: true };

    if(parsedBillId !== null) { //if the bill id had a valid format
      filter = mergeObjects(
        filter,
        {
          issuesExist: true,
          issues: {
            every: {
              issueBillsByReportUuidAndIssueOrdiExist: true,
              issueBillsByReportUuidAndIssueOrdi: {
                every: {
                  billId: {
                    billChamber: { equalTo: parsedBillId.billChamber },
                    billNumber: { equalTo: parsedBillId.billNumber },
                    billResolutionType: billResolutionType
                  },
                  congressNumber: { equalTo: parsedBillId.congressSession }
                }
              }
            }
          }
        }
      )
    }
  }
  if(isMeaningfulString(query.governmentEntities)) {
    const splitEntities = query.governmentEntities.split(",").map(e => e.trim()); //split the entities by commas and trim

    filter = mergeObjects(
      filter,
      {
        issuesExist: true,
        issues: {
          some: {
            issuesGovEntitiesAfter2007SByReportUuidAndIssueOrdiExist: true,
            issuesGovEntitiesAfter2007SByReportUuidAndIssueOrdi: {
              every: {
                govEntity: {
                  in: splitEntities
                }
              }
            }
          }
        }
      }
    )
  }
  if(isMeaningfulString(query.registrantUuid)) {
    filter = mergeObjects(
      filter,
      {
        registrantUuid: {
          equalTo: query.registrantUuid.trim()
        }
      }
    )
  }

  //you can test that the filter is merged properly with this query http://localhost:3000/query?sigName=APPLE&sigNAICS=111&sigSIC=222&sigTicker=APP&sigGVKey=gvkeyTest&year=2014&keywords=defense&issueCode=testCode&issueCodeDescription=testCodeDescription&billId=116_S996
  return filter;
}
