import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import formatLegendIssue from 'utils/formatLegendIssue/formatLegendIssue';

import BarChart from '../barChart/BarChart.js';
import Slider from 'components/slider/Slider';

import processInOutBarChartData from "./processInOutBarChartData/processInOutBarChartData"

import "./inHouseOutsourcedBarChart.scss"

export default class InHouseOutsourcedBarChart extends Component {
  constructor(props){
    super(props)

    this.state = {
      legendKeys: [
        {
          color: '#1F77B4',
          text: 'K-Street Lobbying',
          show: true,
        },
        {
          color: '#AEC7E8',
          text: 'In-House Lobbying',
          show: true,
        }
      ],
      numberOfIssuesShown: 7
    }
  }

  processYearIssueAmountData = memoizeOne(processInOutBarChartData)

  onClickLegendItem = (color) => {
    this.setState({
      legendKeys: this.state.legendKeys.map((item) => {
        //checks if selected item matches the color clicked
        if (item.color === color) {
          //checks if item is already showing on the bar chart
          if (item.show) {
            item.show = false;
          } else {
            item.show = true
          }
        }

        return item;
      })
    })
  }

  onClickBarItem = (color) =>{
    let allTrue = true

    for (let i=0; i<this.state.legendKeys.length; i++){
      if (this.state.legendKeys[i].show === false){
        allTrue = false
        break
        //checks to see if any item is currently not showing
      }
    }

    if (allTrue) {
      this.setState({
        legendKeys: this.state.legendKeys.map((item) => {
          item.show = item.color === color ;
          return item;
        })
      })
      //will only display the data associated with the item clicked in the legend
    }
    else {
      this.setState({
        legendKeys: this.state.legendKeys.map((item) => {
          item.show = true;
          return item;
        })
      })
      //will re-display all data (e.g. reset the chart to the initial showing state)
    }
  }


  render(){
    const processedData = this.processYearIssueAmountData(this.props.yearIssueAmountData, this.state.legendKeys)

    if(processedData.length > 0) {
      const slider = (
        <div>
          <div><strong># Issues to show: {this.state.numberOfIssuesShown}</strong></div>
          <Slider
            step={1}
            min={7}
            max={processedData.length}
            value={this.state.numberOfIssuesShown}
            onChange={e => this.setState({numberOfIssuesShown: e.target.value})}
            color="#1F77B4"
          />
        </div>
      )

      const toggleInHouse = e => this.onClickLegendItem(this.state.legendKeys[0].color)
      const toggleKStreet = e => this.onClickLegendItem(this.state.legendKeys[1].color)

      return (
        <div className="inHouseOutsourcedBarChart">
          <span className="legendIssue">
            <input type="checkbox" checked={this.state.legendKeys[0].show === true} onChange={toggleInHouse}/>
            <span onClick={toggleInHouse}>{formatLegendIssue(this.state.legendKeys[0].text,this.state.legendKeys[0].color)}</span>
          </span>
          <span className="legendIssue">
            <input type="checkbox" checked={this.state.legendKeys[1].show === true} onChange={toggleKStreet}/>
            <span onClick={toggleKStreet}>{formatLegendIssue(this.state.legendKeys[1].text,this.state.legendKeys[1].color)}</span>
          </span>

          <BarChart
            onClickLegendItem={this.onClickLegendItem}
            onClickBarItem={this.onClickBarItem}
            onClickNewIssues={this.onClickNewIssues}
            padding={{xAxis: 20,yAxis: 20}}
            legendKeys={this.state.legendKeys}
            data={processedData.slice(0,this.state.numberOfIssuesShown)}
            showLegend = {false}
            height={300}
            width={this.props.width}
            rotateLabels={true}
            margin={{top: 10, bottom: 50, left: 50, right: 10}}
          />

          <div>{slider}</div>
        </div>
      )
    }
    else if(this.props.requestStatus === "loading") {
      return "Loading...";
    }
    else if(this.props.requestStatus === "error") {
      return "There was an error getting the data. Please try again later.";
    }
    else if(this.props.requestStatus === "done") {
      return "There is no data from the query for this visualization";
    }
    else if(this.props.requestStatus === "") {
      return "Please use the form to get data for this visualization";
    }

    return "";
  }
}

InHouseOutsourcedBarChart.propTypes ={
  width: PropTypes.number.isRequired,
  yearIssueAmountData: PropTypes.array.isRequired,
  requestStatus: PropTypes.string.isRequired
}
