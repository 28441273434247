import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import "./slider.scss";

const Input = styled.input`
  &::-webkit-slider-thumb {
    background: ${(props) => props.color}
  }

  &::-moz-range-thumb {
    background: ${(props) => props.color}
  }
`;

const Slider = props => {
  return (
    <div className="slidecontainer">
      <span><strong>{props.min}</strong></span>
      <Input
        className="slider"
        type="range"
        step={props.step}
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={props.onChange}
        color={props.color}
      />
      <span><strong>{props.max}</strong></span>
    </div>
  )
}

Slider.propTypes = {
  step: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  min: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  max: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func.isRequired,

  color: PropTypes.string
}

Slider.defaultProps = {
  color: "#4CAF50"
}

export default Slider
