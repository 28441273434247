import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import memoize from 'memoize-one'

import capitalize from "utils/capitalize/capitalize";
import formatMoney from "utils/formatMoney/formatMoney";
// import BoldSubstring from "components/boldSubstring/BoldSubstring"
import DownloadModal from "components/downloadModal/DownloadModal";
import GlobalFilter from "components/table/globalFilter/GlobalFilter";
import Pagination from "components/table/pagination/Pagination";
import SortIcons from "components/table/sortIcons/SortIcons";
import formatReportYearAndQuarter from "components/query/formatReportYearAndQuarter"
import "./reportsTable.scss";


export default class ReportsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDownloadDataModal: false
    }
  }

  processDataForReactTable = memoize(
    (reports) => reports.map((r, i) => ({
      name: capitalize(r.client.clientName),
      yearAndQuarter: formatReportYearAndQuarter(r),
      amount: r.amount,
      registrant: capitalize(r.registrant.registrantName),
    }))
  )

  getReports = () => {
    if(this.props.reports.length > 0) {
      const processedDataForReactTable = this.processDataForReactTable(this.props.reports)

      return (
        <React.Fragment>
          <div>
            <Button variant="secondary" onClick={e => this.setState({showDownloadDataModal: true})} style={{float: "right"}}>
              Download Data <FontAwesomeIcon icon={faDownload}/>
            </Button>

            <h2>Reports</h2>

            <DownloadModal
              show={this.state.showDownloadDataModal}
              getDataAsString={e => JSON.stringify(this.props.reports)}
              hideModal={e => this.setState({showDownloadDataModal: false})}
            />
          </div>

          <div>
            {/* TODO include details about the reports here? */}
          </div>

          <ReportsTableContent
            onClickReport={this.props.showDetailedReport}
            reportsData={processedDataForReactTable}
          />
        </React.Fragment>
      )
    }
  }

  getMessage = () => {
    if(this.props.requestStatus === "loading") {
      return <div>Loading Reports...</div>
    }
    else if(this.props.requestStatus === "error") {
      return <div>There was an error getting the reports. Please try again later.</div>
    }
    else if(this.props.requestStatus==="done" && this.props.reports.length===0) {
      return <div>There are no reports associated with your search.</div>
    }
    else if(this.props.requestStatus==="" && this.props.reports.length===0) {
      return <div>Please use the form to get reports data</div>
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.getReports()}

        {this.getMessage()}
      </React.Fragment>
    );
  }
}

ReportsTable.propTypes = {
  reports: PropTypes.array.isRequired,
  requestStatus: PropTypes.string.isRequired,
  numReportsToShow: PropTypes.number.isRequired,
  showDetailedReport: PropTypes.func.isRequired,
  increaseNumReportsToShow: PropTypes.func.isRequired,
};



const REPORTS_TABLE_COLUMNS = [
  {
    Header: 'Client',
    accessor: 'name',
    sortIconType: "alphabetical",
  },
  {
    Header: 'Year (Quarters)',
    accessor: 'yearAndQuarter',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Registrant',
    accessor: 'registrant',
    sortIconType: "alphabetical",
  },
]

function ReportsTableContent({
  onClickReport,
  reportsData,
}) {
  console.log("reportsData",reportsData)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize },
  } = useTable(
    {
      columns: REPORTS_TABLE_COLUMNS,
      data: reportsData,
      initialState: { pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  // Render the UI for your table
  return (
    <React.Fragment>
      <Table responsive hover striped className="reports-table" {...getTableProps()}>
        <thead className="color-gray">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column =>
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}

                  <SortIcons
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    sortIconType={column.sortIconType}
                  />
                </th>
              )}
            </tr>
          ))}

          <tr>
            <th
              colSpan={REPORTS_TABLE_COLUMNS.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                getPlaceholderText={count => `Search ${count} reports by Client, Year (Quarter), Amount, or Registrant...`}
                globalFilter={globalFilter}
                preGlobalFilteredRowsCount={preGlobalFilteredRows.length}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="report-row" {...row.getRowProps()} onClick={e => onClickReport(parseInt(row.id))}>
                {row.cells.map(cell => {
                  if(cell.column.id === "amount") {
                    if(typeof cell.value==="number" && !isNaN(cell.value)) {
                      return <td {...cell.getCellProps()}>${formatMoney(cell.value)}</td>
                    }
                    return <td className="grayBoldText" {...cell.getCellProps()}>-----</td>
                  }

                  return <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    {/* <BoldSubstring
                      str={cell.value}
                      substr={globalFilter || ""}
                    /> */}
                  </td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <hr/>

      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
      />
    </React.Fragment>
  )
}

ReportsTableContent.propTypes = {
  onClickReport: PropTypes.func.isRequired,
  reportsData: PropTypes.array.isRequired,
}
