import gql from 'graphql-tag';

const AUTO_SUGGEST_REGISTRANT = input => gql`
{
  topRegistrants(_approxregistrantname: "${input}") {
    confidence
    candidatesRegistrant {
      registrantName
      registrantUuid
    }
  }
}
`

export default AUTO_SUGGEST_REGISTRANT
