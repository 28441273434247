import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BarChart from '../barChart/BarChart.js';
import Slider from 'components/slider/Slider';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import memoize from 'memoize-one'

import formatMoney from 'utils/formatMoney/formatMoney'

import processGovAgenciesData from './processGovAgenciesData/processGovAgenciesData'

import "./govAgenciesContainer.css"

export default class GovAgenciesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showHouseAndSenate: false,
      yAxis: 0, //Either (0) number of issues or (1) money
      numberOfAgenciesShown: 7
    };
  }

  processData = memoize(processGovAgenciesData)

  render() {
    const data = this.processData(
      this.props.govAgenciesData,
      this.state.showHouseAndSenate,
      this.state.yAxis,
    )

    return (
      <React.Fragment>
        {
          data.length > 0 ?
          <React.Fragment>
            <input style={{marginLeft: '2em', marginRight: '5px'}} type="checkbox" checked={this.state.showHouseAndSenate} onChange={e => this.setState(prevState => ({
              showHouseAndSenate: !prevState.showHouseAndSenate
            }))}/>Include House and Senate

            <input style={{marginLeft: '2em', marginRight: '5px'}} type="radio" name="yAxis" checked={this.state.yAxis === 0} onChange={e => this.setState({yAxis: 0})}/>Number of Issues
            <input style={{marginLeft: '2em', marginRight: '5px'}} type="radio" name="yAxis" checked={this.state.yAxis === 1} onChange={e => this.setState({yAxis: 1})}/>Money Spent

            <Row>
              <Col sm={12} md={12}>
                <div onMouseMove={this.onMouseMove}>
                  <BarChart
                    onClickLegendItem={() => {}}
                    onClickBarItem={() => {}}
                    onClickNewIssues={() => {}}
                    padding={{xAxis: 20,yAxis: 20}}
                    legendKeys={[{
                      color: '#1F77B4',
                      text: this.state.yAxis === 0 ? "Number of Issues" : "Money Spent",
                      show: false,
                      toggle: true
                    }]}
                    tickFormat = {this.state.yAxis === 0 ? {yAxis: (x) => x} : null}
                    formatBars = {(x) => (this.state.yAxis === 0 ? "" : "$") + formatMoney(x)}
                    mapLegendValue = {(x) => x.fullName}
                    showLegend = {false}
                    data={data.slice(0,this.state.numberOfAgenciesShown)}
                    height={300}
                    width={this.props.width}
                    rotateLabels={true}
                    margin={{top: 10, bottom: 50, left: 80, right: 100}}
                    yAxisLabel={this.state.yAxis === 0 ? "Number of Issues" : "Money Spent"}
                  />
                </div>

                <div>
                  <div><strong># Agencies to show: {this.state.numberOfAgenciesShown}</strong></div>
                  <Slider
                    step={1}
                    min={7}
                    max={data.length}
                    value={this.state.numberOfAgenciesShown}
                    onChange={e => this.setState({numberOfAgenciesShown: e.target.value})}
                    color="#1F77B4"
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
          : (this.props.requestStatus === "loading" ? <div>Loading...</div> : <div>Please search for reports using the form to get data for this visualization</div>)

        }
      </React.Fragment>
    );
  }
}

GovAgenciesContainer.propTypes = {
  govAgenciesData: PropTypes.object.isRequired,
  requestStatus: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}
