/*
* toPercent : given a numerator and denominator, returns the percentage
* with a given number decimal points, defaulted to two decimal points
* In the case of a divide by zero, it will return 0 instead of infinity
*/

export default function toPercent(numerator, denominator, decimalPlaces = 2){
  const parsedNumerator = parseFloat(numerator)
  const parsedDenominator = parseFloat(denominator)
  const parsedDecimalPlaces = parseFloat(decimalPlaces)

  if (isNaN(parsedNumerator)) {
    console.warn("percentConverter expecteds parsedNumerator to be a number")
    return "0"
  }
  if (isNaN(parsedDenominator)) {
    console.warn("percentConverter expecteds parsedDenominator to be a number")
    return "0"
  }
  if (parsedDenominator === 0) { //this will account for the divide by zero infinity error when no issues are being displayed
    console.warn("since parsedDenominator is zero, percentConverter returns 0 instead of NaN")
    return "0"
  }
  if (isNaN(parsedDecimalPlaces)) {
    console.warn("percentConverter expecteds parsedDecimalPlaces to be a number")
    return "0"
  }

  return (100*parsedNumerator/parsedDenominator).toFixed(parsedDecimalPlaces)
}
