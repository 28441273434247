import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

import "./pagination.scss"

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50]

export default function Pagination({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  previousPage,
  setPageSize,
}) {
  return (
    <div className="pagination">
      <div>
        <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faAngleDoubleLeft}/>
        </Button>
        <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faAngleLeft}/>
        </Button>
        <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faAngleRight}/>
        </Button>
        <Button variant="secondary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faAngleDoubleRight}/>
        </Button>
      </div>

      <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
      </div>

      <div className="goToPage">
        Go to page:
        <Form.Group controlId="paginationGoToPage">
          <Form.Control
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => gotoPage(
              e.target.value ? parseInt(e.target.value) - 1 : 0
            )}
          />
        </Form.Group>
      </div>


      <div className="paginationPageSizeContainer">
        <Form.Group controlId="paginationPageSize">
          <Form.Control as="select" value={pageSize} onChange={e => setPageSize(parseInt(e.target.value))}>
            {PAGE_SIZE_OPTIONS.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} Rows
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
}
