import React from 'react'
import PropTypes from 'prop-types'
import SocialMedia from "components/socialMedia/SocialMedia"

import "./reportsVizContainer.scss"

const ReportsVizContainer = props => {
  return (
    <div className="reportsVizContainer">
      <div className="reportsVizContainerHeading">
        {props.heading}
      </div>

      <div>
        {props.children}
      </div>

      <br/>

      <div className="reportsVizContainerSocialMedia">
        <SocialMedia/>
      </div>
    </div>
  )
}

ReportsVizContainer.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  responsive: PropTypes.bool
}

export default ReportsVizContainer
