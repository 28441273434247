import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

import "./query.scss";
import Form from "./form/Form.js";
import VizReport from "./vizReport/VizReport.js"
import ReportsTable from "./reportsTable/ReportsTable"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { withApolloClient } from "utils/graphqlSetup"

import processWordCloudData from 'components/viz/wordCloudContainer/processWordCloudData/processWordCloudData'

import objToQueryString from "utils/objToQueryString/objToQueryString";
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";
import REPORTS_QUERY from "utils/graphql/reports/reports";
import getReportsFilter from "utils/graphql/reports/getReportsFilter/getReportsFilter";
import WORD_CLOUD_QUERY from "utils/graphql/wordCloud/wordCloud";
import getGovernmentAgenciesQuery from "utils/graphql/governmentAgencies/governmentAgencies";
import getLobbyistRevolvingDoorQuery from "utils/graphql/lobbyistRevolvingDoor/lobbyistRevolvingDoor";
import getYearIssueAmountQuery from "utils/graphql/yearIssueAmount/yearIssueAmount";
import isEmptyObject from "utils/isEmptyObject/isEmptyObject";
import setDocumentTitle from "utils/setDocumentTitle/setDocumentTitle";


const INITIAL_STATE = {
  reports: [],
  reportsRequestStatus: "", //values: "", "loading", "done", "error"
  reportsRequestFilter: {}, //we store the filter in our state so that the word cloud viz can use it too
  numReportsToShow: 20,
  viz: true, // toggle viz and detailed report

  currentReport: 0,
  currentIssue: 0, // index for number

  wordCloudData: [], //wordCloudData should be a SORTED array of SORTED values
  wordCloudRequestStatus: "",
  yearIssueAmountData: [], //this data is used for both the time series and bar type charts
  yearIssueAmountRequestStatus: "",
  // sankeyData: {nodes: [], links: []},
  timeSeriesData: {points: [], keys: []},
  govAgenciesData: {points: [], keys: []},
  revolvingDoorData: [],
  networkRequestStatus: "",
  govAgenciesRequestStatus: "",
  revolvingDoorRequestStatus: "",
  formVisible: true
}

class Query extends Component {
  constructor(props) {
    super(props);

    setDocumentTitle("Advanced Search")

    this.state = {
      ...INITIAL_STATE
    };

    this.VIZ_KEYS = {
      WORD_CLOUD: "wordCloud",
      TIME_SERIES: "timeSeries",
      // SANKEY: "sankey",
      IN_HOUSE_OUT_SOURCED: "inHouseOutsourced",
      GOV_AGENCIES: "govAgencies",
      REVOLVING_DOOR: "revolvingDoor"
    };
  }



  //this lifecycle hook runs ONCE when the component mounts into the DOM: https://reactjs.org/docs/react-component.html#componentdidmount
  //we use to here to check if there is a query that we need to auto run
  componentDidMount() {
    const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any
    // console.log("component did mount query", query)
    this.getReportsData(query, false);
  }



  /**
  * [gets the reports data based on the query parameters then sets the state]
  * @param  {[object]} query [object of query parameters to be used in the graphql request]
  * @return {[void]}       [sets the state in the function]
  */
  getReportsData = async (query, updateUrl=true) =>  {
    //first clear any old data
    this.setState({
      ...INITIAL_STATE,
      reportsRequestStatus: "loading",
    });

    const filter = await getReportsFilter(query, this.props.client);
    // console.log("reports filter", filter)
    //only send a request if there are filter parameters, otherwise do nothing
    if(!isEmptyObject(filter)) {
      if(updateUrl) {
        navigate(window.location.pathname+"?"+objToQueryString(query)); //change the URL
      }

      this.props.client.query({
        query: REPORTS_QUERY,
        variables: {filter: filter}
      })
      .then((response) => {
        this.setState({
          reportsRequestStatus: "",
        });
        if (!response.data) { throw Error(response.errors[0].message); }
        return response.data;
      }).then((data) => {
        console.log(data.reports)
        this.setState({
          reports: data.reports, //set the reports
          reportsRequestStatus: "done",
          reportsRequestFilter: filter,
        });
      }).catch((error) => {
        this.setState({
          reportsRequestStatus: "error",
        });
        console.error(error);
      });

    }
    else {
      this.setState({
        reportsRequestStatus: "",
      });
      console.warn("The filters are empty");
    }
  }

  //same description as getReportsData
  getWordCloudData = async query => {
    this.setState({
      wordCloudRequestStatus: "loading",
    })

    const filter = this.state.reportsRequestFilter;

    if(!isEmptyObject(filter)) {
      this.props.client.query({
        query: WORD_CLOUD_QUERY,
        variables: {filter: filter}
      }).then((response) => {
        if (!response.data) { throw Error(response.errors[0].message); }
        return response.data;
      }).then((data) => {
        this.setState({
          wordCloudData: processWordCloudData(data.reports),
          wordCloudRequestStatus: "done",
        });

      }).catch((error) => {
        this.setState({
          wordCloudRequestStatus: "error",
        })
        console.error(error);
      });
    }
    else {
      this.setState({
        wordCloudRequestStatus: ""
      });
    }
  }

  getYearIssueAmountData() {
    //if there are reports
    if(this.state.reports.length > 0) {
      this.setState({
        yearIssueAmountRequestStatus: "loading",
      })

      const reportIds = this.state.reports.map(r => r.reportUuid);

      this.props.client.query({
        query: getYearIssueAmountQuery(reportIds)
      }).then((response) => {
        if (!response.data) { throw Error("The response had no data"); }
        if (!response.data.vizGetYearIssueAmount) { throw Error("The response had no data"); }
        return response.data.vizGetYearIssueAmount;
      }).then((data) => {
//         console.log("yearIssueAmountData", data);

        this.setState({
          yearIssueAmountData: data,
          yearIssueAmountRequestStatus: "done"
        });
      }).catch((error) => {
        this.setState({
          yearIssueAmountRequestStatus: "error",
        })
        console.error(error);
      });
    }
    else {
      this.setState({
        yearIssueAmountRequestStatus: ""
      });
    }
  }

  // //same description as getReportsData
  // getSankeyData = query => {
  //   let data = {
  //     "nodes":[
  //       {"node":0,"name":"node0","color":"blue"},
  //       {"node":1,"name":"node1","color":"green"},
  //       {"node":2,"name":"node2","color":"red"},
  //       {"node":3,"name":"node3","color":"orange"},
  //       {"node":4,"name":"node4","color":"yellow"}
  //     ],
  //     "links":[
  //       {"source":0,"target":2,"value":2},
  //       {"source":1,"target":2,"value":2},
  //       {"source":1,"target":3,"value":2},
  //       {"source":0,"target":4,"value":2},
  //       {"source":2,"target":3,"value":2},
  //       {"source":2,"target":4,"value":2},
  //       {"source":3,"target":4,"value":4}
  //     ]
  //   };
  //
  //   this.setState({sankeyData: data});
  // }


  getRevolvingDoorData() {
    if(this.state.reports.length > 0){
      this.setState({
        revolvingDoorRequestStatus: "loading",
      });

      console.log("available data : ",this.state.reports )
      // Get lobbyist uuids from reports
      const lobbyist_uuids = new Set();
      const lobbyistUuidToName = {}; //maps the lobbyist uuid to the name, ie {"lobbyist1-uuid": "lobbyist1-name", ...}

      this.state.reports.forEach(function(report){ //for each report
        report.issues.forEach(function(issue){ //for each issue
          issue.lobbyistsIssuesAfter2007SByReportUuidAndIssueOrdi.forEach(function(lobbyist){ //for each lobbyist
            lobbyistUuidToName[lobbyist.lobbyist.lobbyistUuid] = lobbyist.lobbyist.lobbyistConsolidatedName; //map the lobbyist uuid to the name
            lobbyist_uuids.add(lobbyist.lobbyist.lobbyistUuid); //add this lobbyist uuid to the set
          })
        });
      });
      // console.log("lobbyist_uuids",JSON.stringify(Array.from(lobbyist_uuids)))
      this.props.client.query({
        query: getLobbyistRevolvingDoorQuery(lobbyist_uuids)
      })
      .then((response) => {
        if (!response.data) { throw Error("The response had no data"); }
        if (!response.data.vizGetLobbyistsRevDoorHistory) { throw Error("The response had no data"); }
        return response.data.vizGetLobbyistsRevDoorHistory;
      })
      .then((data) => {
        data = data.map(function(d){ // Add name to each lobbyist
          d.name = lobbyistUuidToName[d.lobbyistUuid];
          return d;
        });

        this.setState({
          revolvingDoorData: data,
          revolvingDoorRequestStatus: data.length ? "" : "empty",
        });
      }).catch(function(error) {
        console.error(error);
      });
    }
  }

  getGovAgenciesData = () => {
    //if there are reports
    if(this.state.reports.length > 0) {
      this.setState({
        govAgenciesRequestStatus: "loading",
      });

      const reportIds = this.state.reports.map(r => r.reportUuid)

      this.props.client.query({
        query: getGovernmentAgenciesQuery(reportIds)
      }).then((response) => {
        if (!response.data) { throw Error("The response had no data"); }
        if (!response.data.vizGetGoventityYearAmount) { throw Error("The response had no data"); }
        return response.data.vizGetGoventityYearAmount;
      }).then((data) => {
        // Segment data by time so it is easier to visualize:
        const dataNew = {};
        const aggregateData = [];
        for(let i = 0; i < data.length; i++){
          for(let j = 0; j < data[i].years.length; j++){
            if(!dataNew[data[i].years[j].report_year]) dataNew[data[i].years[j].report_year] = []; //If year does not yet exist in data, create it

            dataNew[data[i].years[j].report_year].push({
              gov_entity: data[i].gov_entity,
              n_issues: data[i].years[j].n_issues,
              tot_per_year_per_issue_per_inout: data[i].years[j].tot_per_year_per_issue_per_inout,
            });

            //Aggregate data for all the years
            let valueExists = false;
            for(var k = 0; k < aggregateData.length; k++){
              if(aggregateData[k].gov_entity === data[i].gov_entity){
                aggregateData[k].n_issues += data[i].years[j].n_issues
                aggregateData[k].tot_per_year_per_issue_per_inout += data[i].years[j].tot_per_year_per_issue_per_inout
                valueExists = true;
                break;
              }
            }
            if(!valueExists){
              aggregateData.push({
                gov_entity: data[i].gov_entity,
                n_issues: data[i].years[j].n_issues,
                tot_per_year_per_issue_per_inout: data[i].years[j].tot_per_year_per_issue_per_inout,
              });
            }
          }
        }

        this.setState({
          govAgenciesData: {
            points: dataNew,
            aggregate: aggregateData,
            keys: Object.keys(dataNew)
          },
          govAgenciesRequestStatus: "done"
        });
      }).catch(function(error) {
        this.setState({
          govAgenciesRequestStatus: "error",
        })
        console.error(error);
      });
    }
  }

  /**
  * [given a viz name, runs its respective get data function]
  * @param  {[string]} vizName [viz name]
  * @return {[void]}         [returns nothing]
  */
  getVizData = vizName => {
    console.log("getVizData")
    const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any

    //if the user clicked on the word cloud tab AND there is no word cloud data yet
    if(vizName===this.VIZ_KEYS.WORD_CLOUD && this.state.wordCloudData.length===0) {
      console.log("we are getting new word cloud data");
      this.getWordCloudData(query);
    }
    //if the user clicked on the timeSeries or ByType tab AND there is no year issue amount data yet
    if(
      (vizName===this.VIZ_KEYS.TIME_SERIES || vizName===this.VIZ_KEYS.IN_HOUSE_OUT_SOURCED) &&
      this.state.yearIssueAmountData.length===0
    ) {
      console.log("we are getting new timeSeries data");
      this.getYearIssueAmountData(query);
    }
    // //if the user clicked on the sankey tab AND there is no sankey data yet
    // if(vizName===this.VIZ_KEYS.SANKEY && this.state.sankeyData.nodes.length===0) {
    //   console.log("we are getting new sankey data");
    //   this.getSankeyData(query);
    // }

    //if the user clicked on the government agencies tab AND there is no government agency data yet
    if(vizName===this.VIZ_KEYS.GOV_AGENCIES && this.state.govAgenciesData.points.length===0) {
      console.log("we are getting new government agency data");
      this.getGovAgenciesData(query);
    }

    //if the user clicked on the revolving door tab AND there is no revolving door data yet
    if(vizName===this.VIZ_KEYS.REVOLVING_DOOR && this.state.revolvingDoorData.length===0) {
      console.log("we are getting new revolving door data");
      this.getRevolvingDoorData(query);
    }

    query.vizTab = vizName; //set the vizTab query in the query object
    navigate(window.location.pathname+"?"+objToQueryString(query)); //change the URL
  }

  showDetailedReport = i => this.setState({
    viz: false,
    currentReport: i,
    currentIssue: 0,
  })

  /*
  * showViz : sets state to show the vizualizations and
  * hide the detailed report
  */
  showViz = () => this.setState({viz: true})

  setReportIssue = i => this.setState({currentIssue: Number(i)})

  toggleForm = () => this.setState({formVisible: !this.state.formVisible})

  render() {
    return (
      <div id="query" className="page">
        <Helmet>
          <script type="text/javascript" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"/>
        </Helmet>

        <Row>
        <Button id="show-filters-button" onClick={this.toggleForm} style={this.state.formVisible ? {visibility: 'hidden'} : {visibility: 'visible'}}>Filters</Button>
          <Col sm={12} md={3} id="queryFormContainer" className={this.state.formVisible ? 'show' : 'hide'}>
            <div className="box">
              <Form
                getReportsData={this.getReportsData}
                showViz={this.showViz}
                toggleForm={this.toggleForm}
              />
            </div>
          </Col>
          <Col sm={12} md={9} id="results" className={"results " + (this.state.formVisible ? 'show' : 'hide')}>
            <div>
              <VizReport
                viz={this.state.viz}
                reports={this.state.reports}
                reportIdx={this.state.currentReport}
                issueIdx={this.state.currentIssue}
                wordCloudData={this.state.wordCloudData}
                timeSeriesData={this.state.timeSeriesData}
                govAgenciesData={this.state.govAgenciesData}
                govAgenciesRequestStatus={this.state.govAgenciesRequestStatus}
                revolvingDoorData={this.state.revolvingDoorData}
                wordCloudRequestStatus={this.state.wordCloudRequestStatus}
                yearIssueAmountData={this.state.yearIssueAmountData}
                yearIssueAmountRequestStatus={this.state.yearIssueAmountRequestStatus}
                revolvingDoorRequestStatus={this.state.revolvingDoorRequestStatus}
                // sankeyData={this.state.sankeyData}

                getVizData={this.getVizData}
                showViz={this.showViz}
                setReportIssue={this.setReportIssue}

                VIZ_KEYS={this.VIZ_KEYS}
              />
            </div>

            <br/>

            <div className="box">
              <ReportsTable
                reports={this.state.reports}
                requestStatus={this.state.reportsRequestStatus}
                numReportsToShow={this.state.numReportsToShow}
                showDetailedReport={this.showDetailedReport}
                increaseNumReportsToShow={() => this.setState({numReportsToShow: this.state.numReportsToShow + 20})}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}


Query.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withApolloClient(Query)
