import gql from 'graphql-tag';

const AUTO_SUGGEST_SIG_SIC = input => gql`
{
  topSic4(inputWrongSic: "${input}") {
    candidateSic4Code
    sic4Description
    confidence
  }
}
`

export default AUTO_SUGGEST_SIG_SIC
