import binaryInsert from 'utils/binaryInsert/binaryInsert';

type IssueType = {
  in_house: number | null,
  issue_code: string | null,
  out_house: number | null,
  super_issue_code: number,
}

type ReportYearIssues = {
  issues: IssueType[],
  report_year: number,
}



type IssueCodeValueType = {
  code: string,
  value: number
}



type PointType = {
  [issueCode: string]: number | Date,
  date: Date,
}

type TimeSeriesDataType = {
  keys: string[],
  points: PointType[],
}

/**
 * given the data from the server, process the data for the time series viz
 * @param  data              reports data from the server
 * @param  maxNumberOfIssues issues that are too small are lumped into 'other'
 * @return                   keys and points for the time series viz
 */
export default function processTimeSeriesData(
  data:ReportYearIssues[],
  maxNumberOfIssues: number = 6,
):TimeSeriesDataType {
  //this can almost certainly use improvements, but the general idea is to:
  //1) go through the years then issues so we don't miss any issues, since different years could have different issues
  //2) find the top N biggest issues across all the years
  //3) for each year, leave the top N biggest issues and sum all the smaller issues into an "other" issue

  //part 1)
  const timelinePoints:PointType[] = [];
  const encounteredIssueCodes = new Set<string>(); //we need to track all the issue codes we encounter in case different years have different codes
  for(let yearIndex=0; yearIndex<data.length; ++yearIndex) {
    const oneYearData:PointType = {
      date: new Date(data[yearIndex].report_year+1, 0, 0),
    };

    for(let issueIndex=0; issueIndex<data[yearIndex].issues.length; ++issueIndex) {
      const issue = data[yearIndex].issues[issueIndex]
      const issueCode = issue.issue_code;
      //sometimes the issue code is null, so we only want to look at string issue codes
      if(typeof issueCode === "string") {
        encounteredIssueCodes.add( issueCode ); //mark that we have seen this issue code

        const inHouseValue = issue.in_house || 0;
        const outHouseValue = issue.out_house || 0;

        //if we have seen this code before, increment the value
        if(typeof oneYearData[issueCode] === "number") {
          (oneYearData[issueCode] as number) += inHouseValue + outHouseValue;
        }
        else { //else create a new key value pair
          oneYearData[issueCode] = inHouseValue + outHouseValue;
        }
      }
    }

    timelinePoints.push(oneYearData); //push this data point into the array
  }

  //loop through data again and set any missing issues to 0
  for(let yearIndex=0; yearIndex<data.length; ++yearIndex) {
    for(let timelineDataIndex=0; timelineDataIndex<timelinePoints.length; ++timelineDataIndex) {
      encounteredIssueCodes.forEach(issueCode => {
        //if this point does not have a number key value pair for this issue code
        if(typeof timelinePoints[timelineDataIndex][issueCode] !== "number") {
          timelinePoints[timelineDataIndex][issueCode] = 0; //set the key value pair to 0
        }
      })
    }
  }


  //part 2)
  //at this point, for all the years, we have the summed values for all the issue codes we've encountered
  //now we need to do some processing to get the top most valued issue codes
  const totalSummedValues:{[issueCode: string]: number} = {};
  encounteredIssueCodes.forEach(issueCode => {
    totalSummedValues[issueCode] = 0; //initialize all issue sums to 0
  })

  for(let yearIndex=0; yearIndex<timelinePoints.length; ++yearIndex) {
    for(const field in timelinePoints[yearIndex]) {
      //if this isn't the date field
      if(field !== "date") {
        totalSummedValues[field] += timelinePoints[yearIndex][field] as number; //sum the values
      }
    }
  }

  //at this point we have the summed values for each issue code
  //now we need to find the Nth highest issue codes
  //we will do this by:
  //2a) looping through all issues
  //2b) binaryInsert-ing them into the array if it is big enough
  //2c) removing any issues too small
  //then we will be left with an N length array of the highest issues
  const topIssues:IssueCodeValueType[] = [];
  const issueComparator = (a:IssueCodeValueType, b:IssueCodeValueType) => (
    a.value>b.value ? -1 : 1 //DESCENDING order (high first to low last)
  )
  //part 2a)
  for(const issueCode in totalSummedValues) {
    const issue:IssueCodeValueType = {code: issueCode, value: totalSummedValues[issueCode]};
    //if topIssues is too short OR this issue has a higher value than the lowest issue in the array
    if(topIssues.length<maxNumberOfIssues || issue.value>topIssues[topIssues.length-1]?.value) {
      binaryInsert(topIssues, issue, false, issueComparator); //part 2b) binary insert it into the array

      //part 2c) if topIssues is too long, remove the first element
      if(topIssues.length > maxNumberOfIssues) {
        topIssues.pop(); //remove the issue that is now too small
      }
    }
  }

  //move the issues to an object for easy access
  const topIssuesObject:{[issueCode: string]: number} = {};
  for(let topIssuesIndex=0; topIssuesIndex<topIssues.length; ++topIssuesIndex) {
    topIssuesObject[ topIssues[topIssuesIndex].code ] = topIssues[topIssuesIndex].value;
  }


  //part 3)
  //now delete all non top issues and combine them into an "other" issue
  for(let yearIndex=0; yearIndex<timelinePoints.length; ++yearIndex) {
    let otherSum = 0;

    for(const field in timelinePoints[yearIndex]) {
      //if this isn't the date field AND it's not a top issue
      if(field!=="date" && topIssuesObject[field]===undefined) {
        otherSum += timelinePoints[yearIndex][field] as number; //sum value into other
        delete timelinePoints[yearIndex][field]; //delete field
      }
    }

    timelinePoints[yearIndex].other = otherSum; //make new "other" issue
  }


  return {
    points: timelinePoints,
    keys: topIssues.map(issue => issue.code).concat("other")
  };
}
